import classNames from 'classnames';
import {VFC} from 'react';

import styles from './MenuItem.module.css';

type MenuItemProps = {
	id: number;
	title: string;
	Img: VFC<React.SVGProps<SVGSVGElement>>;
	isActive: boolean;
	onClick: () => void;
};

export function MenuItem({title, Img, isActive, onClick, id}: MenuItemProps) {
	return (
		<li className={classNames(styles.root, {[styles.rootActive]: isActive})} onClick={onClick}>
			<div
				className={classNames(styles.imgWrapper, {
					[styles.imgWrapperBlue]: id % 2 === 0,
					[styles.imgWrapperGreen]: id % 2 !== 0,
				})}
			>
				<Img />
			</div>
			<span>{title}</span>
		</li>
	);
}
